* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Roboto', sans-serif;
}
a {
    text-decoration: none;
    display: block;
    color: #2e3a53;
}
p {
    color: #2e3a53;
}
b {
    font-weight: 500;
}
.hide-desktop {
    display: none;
}
ul {
    list-style-type: none;
}
video {
    outline: none;
}
button {
    background: none;
    outline: none;
    border: none;
    cursor: pointer;
}
input {
    outline: none;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
}
.MuiFormControlLabel-label.MuiTypography-body1 {
    font-size: 0.875rem;
    color: #2e3a53;
}
.MuiAccordion-root {
    box-shadow: none !important;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
    display: none;
}
@media only screen and (max-width: 680px) {
    .hide-mobile {
        display: none;
    }
    .hide-desktop {
        display: block;
    }
}
